import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadCSwidget } from '../../helpers/bazaarVoice';

const BuyNowButton = ({ sku, name }) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            loadCSwidget();
        }
    }, []);

    const handleClick = () => {
        loadCSwidget();
    };

    return (
        <div className="ps-widget" role="button" aria-label={`${name} - ${sku} - shop now.`}>
            <button
                type="button"
                className={sku ? 'bn-enabled cswidget event_buy_now' : 'bn-disabled cswidget event_buy_now'}
                data-asset-id="6072"
                data-product-sku={`${sku}`}
                data-action-detail={`${name} - ${sku}`}
                onClick={handleClick}
            >
                BUY NOW
            </button>
        </div>
    );
};

BuyNowButton.propTypes = {
    sku: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    name: PropTypes.string,
};

export default BuyNowButton;
